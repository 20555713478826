import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {}
  },
  reducers: {
    getUserDetailsSuccess(state, { payload }) {
      state.data = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { getUserDetailsSuccess } = userSlice.actions;

export default userSlice.reducer;
