import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { AUTH_REDIRECT } from './constants';

const AUTH_COOKIE_NAME = 'mastercard-poc';

interface JwtPayload {
  asurite: string;
  exp: number;
  iat: number;
  iss: string;
}

export function getJwtToken() {
  const jwtToken = Cookies.get(AUTH_COOKIE_NAME);
  return jwtToken;
}

export function isExpired(tokenExp?: number) {
  const currentTime = Math.floor(Date.now() / 1000);

  return !!tokenExp && tokenExp <= currentTime;
}

export function getDecodedAuthCookie() {
  const jwtToken = getJwtToken();
  if (!jwtToken) {
    return false;
  }

  const parsedToken = jwtDecode<JwtPayload>(jwtToken);
  return {
    decodedToken: parsedToken,
    isExpired: isExpired(parsedToken.exp)
  };
}

export function getUserAsuriteFromAuthCookie(): string | undefined {
  const decodedAuthCookie = getDecodedAuthCookie();
  if (!decodedAuthCookie) {
    return undefined;
  }

  if (!decodedAuthCookie.decodedToken) {
    return undefined;
  }

  return decodedAuthCookie.decodedToken.asurite;
}

export function isAuthCookieValid() {
  const decodedAuthCookie = getDecodedAuthCookie();
  return decodedAuthCookie === false ? false : !decodedAuthCookie.isExpired;
}

export const redirectToAuth = () => {
  if (window.location.hostname === 'localhost') {
    console.error(
      `Please update the auth cookie manually. You must log in at ${AUTH_REDIRECT}, copy to localhost the auth cookie generated from it, and reload the page.`
    );
    return;
  } else {
    window.location.replace(AUTH_REDIRECT);
  }
};
