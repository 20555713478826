import { useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { isAuthCookieValid, redirectToAuth } from './cookie';

const SOCKET_URL = 'wss://apiws-mcf-nonprod.aiml.asu.edu/';

export const WebSocketHandlers = () => {
  // HOOKS
  const getSocketUrl = useCallback(() => {
    // const token = getJwtToken();
    const params = new URLSearchParams(window.location.search);
    return `${SOCKET_URL}?access_token=${params.get('access_token')}`;
  }, []);

  // CONST VALS
  const { sendJsonMessage, lastMessage, readyState, getWebSocket } = useWebSocket(getSocketUrl, {
    onOpen: () => {
      // TODO: Implement onOpen functionality or remove if not needed
    },
    shouldReconnect: () => true,
    reconnectAttempts: 3,
    onError: () => {
      const authCookieIsValid = isAuthCookieValid();
      if (!authCookieIsValid) {
        redirectToAuth();
      }
    }
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState];

  return {
    sendJsonMessage,
    lastMessage,
    status: connectionStatus,
    getWebSocket
  };
};
