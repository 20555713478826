import { Layout, Typography, Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
// import Dropdown from './atoms/Dropdown';
import { useMediaQuery } from 'usehooks-ts';
import { useAppDispatch } from '../redux/hook';
import { refreshMessages } from '../redux/reducers/chat-reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
// import { updateFilter, resetFilter } from '../redux/reducers/filter-reducer';

const { Sider } = Layout;
const { Title } = Typography;

const LogoWrapper = styled.div`
  margin: 0 auto 24px;
  text-align: center;

  img {
    width: 120px;
  }
`;

const LogoTitle = styled(Title)`
  &&& {
    text-align: center;
    color: #fff;
    font-weight: 600;
  }
`;

const SidebarWrapper = styled(Sider)`
  &&& {
    height: 100%;
    overflow-y: auto;

    @media (max-width: 768px) {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
    }
  }
`;

// const ResetButtonWrapper = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   margin-bottom: 16px;
// `;

// const ResetButton = styled.button`
//   border: 2px solid #ed4e23;
//   border-radius: 16px;
//   color: #fff;
//   background: transparent;
//   font-weight: bold;
//   padding: 4px 8px;
//   cursor: pointer;
// `;

const StyledMenuCloseButton = styled(Button)<{ collapsed: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  border: 0;
  padding: 0;
  border-radius: 0;
  width: 54px;
  height: 54px;
  background: transparent linear-gradient(90deg, #d22a2f 0%, #ff661b 100%) 0% 0% no-repeat padding-box !important;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: transform 0.2s ease-in-out;
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    img {
      transform: rotate(180deg);
    }
  `}
`;

const HomeButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
`;

const HomeButton = styled.button`
  border: none;
  color: #fff;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 22px;

  &:hover {
    color: #ed4e23;
  }
`;

const SidebarText = styled.p`
  color: #fff;
  font-size: 16px;
  line-height: 24px;

  @media (min-width: 769px) {
    padding-left: 12px;
  }
`;

interface SidebarProps {
  collapsed: boolean;
  onClose: () => void;
}

// const CountryOptions = {
//   'Burkina Faso': 'Burkina Faso',
//   Canada: 'Canada',
//   Ethiopia: 'Ethiopia',
//   Ghana: 'Ghana',
//   Kenya: 'Kenya',
//   Morocco: 'Morocco',
//   Nigeria: 'Nigeria',
//   Rwanda: 'Rwanda',
//   Senegal: 'Senegal',
//   'South Africa': 'South Africa',
//   Uganda: 'Uganda'
// };

// const YearOptions = {
//   '2024': '2024',
//   '2023': '2023',
//   '2022': '2022',
//   '2021': '2021',
//   '2020': '2020',
//   '2019': '2019',
//   '2018': '2018',
//   '2017': '2017',
//   '2016': '2016',
//   '2015': '2015',
//   '2014': '2014',
//   '2013': '2013',
//   '2012': '2012',
//   '2011': '2011',
//   '2010': '2010',
//   '2009': '2009',
//   '2008': '2008',
//   '2007': '2007',
//   '2006': '2006'
// };

// const ThemeOptions = {
//   'Education and Skills': 'Education and Skills',
//   'Agriculture>Environmental Sustainability': 'Agriculture & Environmental Sustainability',
//   'Disability, Diversity and Inclusion': 'Disability, Diversity and Inclusion',
//   Other: 'Other',
//   Health: 'Health'
// };

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onClose }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  // const filter = useAppSelector((state) => state.filter);

  // const handleDropdownChange = (filterName: string) => (value: string | string[]) => {
  //   dispatch(updateFilter({ name: filterName, value }));
  // };

  return (
    <SidebarWrapper
      width={isMobile ? '100vw' : 300}
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={0}
    >
      {collapsed ? null : (
        <div style={{ padding: '12px 12px 80px' }}>
          <HomeButtonWrapper>
            <HomeButton onClick={() => dispatch(refreshMessages())}>
              <FontAwesomeIcon icon={faHome} />
            </HomeButton>
          </HomeButtonWrapper>
          <LogoWrapper>
            <img src="/assets/images/logo-post-2.png" alt="" />
          </LogoWrapper>
          <LogoTitle level={4}>Corporate Insights AI Agent</LogoTitle>
          <SidebarText>
            Enhance your workflow with generative AI. It can help to rapidly explore Mastercard Foundation corporate history, accelerate research, craft clearer communications, assist with creative work, and much more.  For any information destined for public release, it’s essential to consult the Mastercard Foundation guidelines.
          </SidebarText>
          {/* <ResetButtonWrapper>
            <ResetButton onClick={() => dispatch(resetFilter())}>Reset all</ResetButton>
          </ResetButtonWrapper>
          <Dropdown
            name="Country"
            multiple
            value={filter.country}
            displayValue={filter.country.map(country => CountryOptions[country as keyof typeof CountryOptions]).join(', ')}
            options={CountryOptions}
            onChange={handleDropdownChange('country')}
          />
          <Dropdown
            name="Year"
            multiple
            value={filter.year}
            displayValue={filter.year.map(year => YearOptions[year as keyof typeof YearOptions]).join(', ')}
            options={YearOptions}
            onChange={handleDropdownChange('year')}
          />
          <Dropdown
            name="Theme"
            multiple
            value={filter.theme}
            displayValue={filter.theme.map(theme => ThemeOptions[theme as keyof typeof ThemeOptions]).join(', ')}
            options={ThemeOptions}
            onChange={handleDropdownChange('theme')}
          /> */}
          {isMobile && (
            <StyledMenuCloseButton type="text" onClick={onClose} collapsed={collapsed}>
              <img src="/assets/svgs/ic-menu-close.svg" alt="" />
            </StyledMenuCloseButton>
          )}
        </div>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
