import { createSlice } from '@reduxjs/toolkit';

const DefaultFilter = {
  country: [],
  year: [],
  theme: []
};

export interface FilterState {
  country: string[];
  year: string[];
  theme: string[];
}

const initialState: FilterState = DefaultFilter;
export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    resetFilter(state) {
      state.country = DefaultFilter.country;
      state.year = DefaultFilter.year;
      state.theme = DefaultFilter.theme;
    },
    updateFilter(state, action) {
      const { name, value } = action.payload;

      if (name in state) {
        state[name as keyof FilterState] = value;
      }
    }
  }
});

// Action creators are generated for each case reducer function
export const { resetFilter, updateFilter } = filterSlice.actions;

export default filterSlice.reducer;
