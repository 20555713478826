export const MODEL_OPTIONS = [
  {
    label: 'Titan Lite (4k)',
    value: 'titang1lite',
    provider: 'aws'
  },
  {
    label: 'Titan Express (8k)',
    value: 'titang1express',
    provider: 'aws'
  },
  {
    label: 'Claude 2.1',
    value: 'claude2_1',
    provider: 'aws'
  },
  {
    label: 'Claude 2',
    value: 'claude2',
    provider: 'aws'
  },
  {
    label: 'Claude Instant',
    value: 'claudeinstant',
    provider: 'aws'
  },
  // {
  //   label: "J2 Ultra",
  //   value: "j2ultra",
  //   provider: "aws",
  // },
  // {
  //   label: "J2 Mid",
  //   value: "j2mid",
  //   provider: "aws",
  // },
  {
    label: 'Command',
    value: 'command',
    provider: 'aws'
  },
  {
    label: 'Command Light',
    value: 'commandlight',
    provider: 'aws'
  },
  // {
  //   label: "Llama 2",
  //   value: "llama2-13b",
  //   provider: "aws",
  // },
  {
    label: 'GPT 3.5',
    value: 'gpt3_5',
    provider: 'azure'
  },
  {
    label: 'GPT 3.5 (16k)',
    value: 'gpt3_5-16k',
    provider: 'azure'
  },
  {
    label: 'GPT 4',
    value: 'gpt4',
    provider: 'azure'
  },
  {
    label: 'GPT 4 (32k)',
    value: 'gpt4-32k',
    provider: 'azure'
  },
  {
    label: 'Bison',
    value: 'bison',
    provider: 'gcp'
  },
  {
    label: 'Bison (32k)',
    value: 'bison-32k',
    provider: 'gcp'
  },
  {
    label: 'Gemini Pro',
    value: 'geminipro',
    provider: 'gcp-deepmind'
  }
  // {
  //   label: "Azure GPT 3.5",
  //   value: "azure-gpt35",
  // },
  // {
  //   label: "Azure GPT 4",
  //   value: "azure-gpt4",
  // },
  // {
  //   label: "Google Bison",
  //   value: "google-bison",
  // },
  // {
  //   label: "Google Bison 001",
  //   value: "google-bison-001",
  // },
  // {
  //   label: "Anthropic Claude 2",
  //   value: "anthropic-claude2",
  // },
  // {
  //   label: "Aiml Llama 2",
  //   value: "aiml-llama2",
  // },
];

export const AUTH_REDIRECT =
  'https://weblogin.asu.edu/cas/login?service=https://auth-main-poc.aiml.asu.edu/showdown/?aid=fJE6wzmdh65dDuiVbxryJk%26redirect=https://mastercard-poc.aiml.asu.edu';

// export const ExampleQuestions = [
//   { title: 'As I am new at the Foundation,', desription: 'what are the key questions that I should ask to understand the Foundation better?' },
//   { title: 'Please share the key timeline of the history of the Foundation.', desription: 'Include executive staff hires, milestone decisions, signature events, and [...].' },
//   { title: 'Share top 10 learnings of the Foundation', desription: 'in terms of Youth Education' },
//   { title: 'Share top 10 insights/learnings', desription: 'from Reeta’s speech over the past 10 years.' }
// ];

export const ExampleQuestions = [
  {
    title: 'Brainstorm',
    questions: [
      {
        title: 'Explore successful models of digital financial inclusion globally.',
        desription: 'Assess applicability and potential impact for African and Indigenous Canadian communities.'
      },
      {
        title: 'Propose innovative solutions for Indigenous Canadian youth education barriers.',
        desription: ''
      },
      // {
      //   title: 'Advise on digital fundraising strategies for increased engagement and donations.',
      //   desription: ''
      // },
      {
        title: 'Develop sustainable job creation strategies for African youth amidst climate change.',
        desription: ''
      },
      {
        title: 'Identify new key focus areas aligning with Mastercard Foundation goals.',
        desription: ''
      }
    ]
  },
  {
    title: 'Communicate',
    questions: [
      {
        title: 'Utilizing your expertise as both the Mastercard Foundation CEO and expert keynote speaker, draft a keynote address to the UN in an inspiring tone.',
        desription: 'Include an outline at the top.'
      },
      {
        title: 'What is distinctive about the Mastercard Foundation?',
        desription: ''
      },
      {
        title: 'Draft a cross-departmental newsletter on lessons learned from the Scholars program and also translate it into the Swahili language.',
        desription: ''
      }
    ]
  },
  {
    title: 'Design',
    questions: [
      {
        title: 'Identify things to avoid in designing a new Foundation program like the Scholars Program.',
        desription: ''
      },
      {
        title: 'I want to design an AI scholars program consistent with Foundation values.',
        desription: 'What might be some components of a program?'
      },
      {
        title: 'Outline obstacles faced in designing the Mastercard Foundation Scholar Program.',
        desription: ''
      },
      {
        title: 'What are potential improvements to the Foundation\'s educational programs based on its learnings?',
        desription: ''
      }
    ]
  }
];
