import React, { useState, useRef, useEffect } from 'react';
import { ConfigProvider, Layout, Button, theme, message, Card as AntCard } from 'antd';
import styled from 'styled-components';
import Sidebar from './components/Sidebar';
import InputBox from './components/atoms/InputBox';
import { Message } from './types/message';
import { ExampleQuestions, MODEL_OPTIONS } from './utils/constants';
import { WebSocketHandlers } from './utils/socket';
import { sendMessageRequest, recieveMessageRequest } from './redux/reducers/chat-reducer';
import { useAppDispatch, useAppSelector } from './redux/hook';
import MessageBox from './components/MessageBox';
import { useMediaQuery } from 'usehooks-ts';
import { getUserAsuriteFromAuthCookie } from './utils/cookie';
import { getUserDetailsSuccess } from './redux/reducers/user-reducer';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
// import MediaLibraryModal from './components/MediaLibraryModal';
import { callApi } from './utils/api';

const { Header, Content } = Layout;

const StyledHeader = styled(Header)`
  &&& {
    display: flex;
    border: 1px solid #d0d0d0;
    align-items: center;
    justify-content: space-between;
    margin-left: -1px;
  }
`;

const StyledMenuCloseButton = styled(Button)<{ collapsed: boolean }>`
  font-size: 16px;
  border: 0;
  padding: 0;
  border-radius: 0;
  width: 56px;
  height: 56px;
  background: transparent linear-gradient(90deg, #d22a2f 0%, #ff661b 100%) 0% 0% no-repeat padding-box !important;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    transition: transform 0.2s ease-in-out;
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    img {
      transform: rotate(180deg);
    }
  `}
`;

const StyledContent = styled(Content)`
  &&& {
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
`;

const StyledAvatar = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-right: 40px;
  font-size: 16px;
  line-height: 18px;
  color: #191919;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

const LogoWrapper = styled.div`
  width: 844px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;

  h1 {
    margin: 14px 0 0;
    font-size: 22px;
  }
`;

const MessagesWrapper = styled.div`
  flex: 1;
  padding: 0 16px;
  height: calc(100% - 145px);
  overflow: auto;
`;

const MessageList = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 20px 0;
  width: 1000px;
`;

// const SplashBox = styled.div`
//   align-items: center;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   justify-content: center;

//   h3 {
//     bottom: 30px;
//     color: #000;
//     font-size: 20px;
//     line-height: 30px;
//     max-width: 100%;
//     position: relative;
//     text-align: center;
//     width: 480px;
//     max-width: 100%;
//   }

//   img {
//     max-width: 100%;
//   }
// `;

const InputWrapper = styled.div`
  align-items: center;
  background: #f1efeb;
  display: flex;
  height: 145px;
  justify-content: center;
  padding: 0 15px;
  width: 100%;
`;

const Container = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  overflow: hidden;
`;

const Card = styled(AntCard)`
  display: flex;
  flex-direction: column;

  > .ant-card-head {
    padding: 12px;
    min-height: 0;
    border: 0;
  }

  > .ant-card-body {
    overflow: auto;
    flex: 1;
    padding: 12px;
  }
`;

const OuterCard = styled(Card)`
  height: 100%;
  background-color: #f1efeb;
  padding-bottom: 10px;
  flex: 1;

  > .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card:last-child {
    margin-bottom: 0;
  }
`;

const InnerCard = styled(Card)`
  margin-bottom: 10px;
`;

// const ExampleQuestionCard = styled.div`
//   border: 2px solid #ed4e23;
//   border-radius: 10px;
//   padding: 16px;
//   margin-bottom: 8px;
//   height: calc(100% - 10px);
//   position: relative;
//   cursor: pointer;

//   h5 {
//     margin: 0;
//     font-size: 18px;
//     font-weight: bold;
//     line-height: 20px;
//     margin-bottom: 8px;
//   }

//   h6 {
//     margin: 0;
//     font-size: 16px;
//     font-weight: 400;
//     line-height: 17px;
//     margin-bottom: 8px;
//   }

//   .icon-info {
//     position: absolute;
//     width: 28px;
//     height: 28px;
//     border-radius: 8px;
//     background-color: white;
//     display: none;
//     justify-content: center;
//     align-items: center;
//     bottom: 16px;
//     right: 16px;
//   }

//   &:hover {
//     background-color: #eeeeee;

//     .icon-info {
//       display: flex;
//     }
//   }
// `;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const messageBox = useRef<HTMLDivElement>(null);
  const messageHistory = useRef<Message | null>(null);
  const [loading, setLoading] = useState(false);
  const [messageStatus, setMessageStatus] = useState(false);
  const [selectedModel] = useState(MODEL_OPTIONS[4]);
  // const [restrictAsuData] = useState(false);
  // const [searchFromMyFiles] = useState(false);
  const { sendJsonMessage, lastMessage, status } = WebSocketHandlers();
  const { messages } = useAppSelector((state) => state.chat);
  const filter = useAppSelector((state) => state.filter);
  const { data: userData } = useAppSelector<any>((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const url = `https://search.asu.edu/api/v1/webdir-profiles/faculty-staff/filtered?asurite_ids=${getUserAsuriteFromAuthCookie()}`;
    callApi(url)
      .then((res) => {
        dispatch(getUserDetailsSuccess(res.results[0]));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(isMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial state

    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const autoScroll = () => {
    setTimeout(() => {
      messageBox.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    });
  };

  const showErrorMsg = () => {
    if (messageStatus) return;
    messageApi.open({
      type: 'error',
      content: 'Sorry there was an issue with the request, please check your access token and try again',
    });
    setMessageStatus(true);
  };

  const sendMessageHandler = (message: string) => {
    setLoading(true);
    // NEED TO REMOVE AFTER TESTING
    if (messageHistory.current) {
      messageHistory.current.isLoading = true;
    }
    const messagePayload = {
      action: 'query',
      model_provider: selectedModel.provider,
      model_name: selectedModel.value,
      query: message,
      // enable_search: restrictAsuData || searchFromMyFiles,
      enable_search: true,
      search_params: {
        top_k: 20,
        country: filter.country,
        year: filter.year.map(year => parseInt(year)),
        theme: filter.theme
      }
    };
    // send message to websocket
    sendJsonMessage(messagePayload);
    // save message to reducer
    dispatch(sendMessageRequest(message));
    // save message to history
    const payload = {
      isCompleted: false,
      isLoading: true,
      isPrompt: true,
      model: 'Bot',
      message: ''
    };
    messageHistory.current = payload;
    autoScroll();
  };

  // const stopResponseGenerating = () => {
  //   const sock = getWebSocket();
  //   if (sock) {
  //     sock.close();
  //   }
  //   setLoading(false);
  //   if (messageHistory.current) messageHistory.current['isCompleted'] = true;
  //   dispatch(recieveMessageRequest(messageHistory.current));
  //   messageHistory.current = null;
  // };

  // HOOKS
  useEffect(() => {
    if (!lastMessage || !messageHistory.current) return;
    // NEED TO REMOVE AFTER TESTING
    if (loading) setLoading(false);
    // NEED TO TEST AFTER UPDATE
    if (lastMessage.data.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current['isCompleted'] = true;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if (lastMessage.data.includes(`message":`)) {
      // navigateToLogin();
      return;
    }
    const message = messageHistory.current.message.concat(lastMessage.data);
    const payload = {
      isCompleted: false,
      isLoading: false,
      isPrompt: true,
      model: 'Bot',
      message
    };
    messageHistory.current = payload;
    autoScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  useEffect(() => {
    if (status !== 'Connected' && status !== 'Connecting' && loading) {
      showErrorMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, loading]);

  return (
    <ConfigProvider
      theme={{ components: { Layout: { siderBg: '#2B2B2B', headerHeight: 56, headerPadding: 0, headerBg: '#fff' } } }}
    >
      {contextHolder}
      <Layout style={{ height: '100vh' }}>
        <Sidebar collapsed={collapsed} onClose={() => setCollapsed(true)} />
        <Layout>
          <StyledHeader>
            <StyledMenuCloseButton type="text" onClick={() => setCollapsed(!collapsed)} collapsed={collapsed}>
              <img src="/assets/svgs/ic-menu-close.svg" alt="" />
            </StyledMenuCloseButton>
            <StyledAvatar>
              {userData?.display_name?.raw}
              <img src={userData?.photo_url?.raw} alt={userData?.display_name?.raw} />
            </StyledAvatar>
          </StyledHeader>
          <StyledContent
            style={{
              minHeight: 280,
              background: colorBgContainer
            }}
          >
            {messages.length === 0 && (
              <LogoWrapper>
                <h1>MasterCard Foundation<br />Corporate Insights AI Agent</h1>
              </LogoWrapper>
            )}
            <MessagesWrapper>
              {messages.length >= 1 ? (
                <>
                  <MessageList>
                    {messages.map((message, index) => (
                      <MessageBox
                        key={index}
                        data={message}
                        isLoading={loading}
                        regenerateMessage={sendMessageHandler}
                      />
                    ))}
                    {messageHistory.current && <MessageBox data={messageHistory.current} />}
                  </MessageList>
                  <div className="scroller-box" ref={messageBox} />
                </>
              ) : (
                <Container>
                  {ExampleQuestions.map((column, idx) => (
                    <OuterCard title={column.title} bordered={true} key={idx}>
                      {column.questions.map((question, index) => (
                        <InnerCard
                          bordered={true}
                          key={index}
                          hoverable={true}
                          onClick={() => sendMessageHandler(`${question.title} ${question.desription}`)}
                        >
                          {question.title} {question.desription}
                        </InnerCard>
                      ))}
                    </OuterCard>
                  ))}
                  {/* <Container>
                    <Row gutter={8}>
                      {ExampleQuestions.map((question, index) => (
                        <Col
                          key={index}
                          span={12}
                          onClick={() => sendMessageHandler(`${question.title} ${question.desription}`)}
                        >
                          <ExampleQuestionCard>
                            <h5>{question.title}</h5>
                            <h6>{question.desription}</h6>
                            <div className="icon-info">
                              <FontAwesomeIcon icon={faArrowUp} />
                            </div>
                          </ExampleQuestionCard>
                        </Col>
                      ))}
                    </Row>
                  </Container> */}
                </Container>
              )}
            </MessagesWrapper>
            <InputWrapper>
              <InputBox sendMessage={sendMessageHandler} isLoading={loading} />
            </InputWrapper>
          </StyledContent>
        </Layout>
        {/* <MediaLibraryModal /> */}
      </Layout>
    </ConfigProvider>
  );
};

export default App;
