import { getJwtToken } from "./cookie";

export function callApi(url: string, method = 'GET', body: any = {}) {
  let req: RequestInit = {
    method,
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  };
  if (method !== 'GET') {
    req.body = body;
  }
  return fetch(url, req).then(res => res.json());
}
