import { useState } from 'react';
import { Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat, faCircleCheck, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { PulseLoader } from 'react-spinners';
import { Message } from '../types/message';

const MessageBoxWrapper = styled.div<{ isPrompt?: boolean }>`
  margin-top: 20px;
  font-size: 16px;
  line-height: 22px;
  color: #191919;
  white-space: pre-line;
  word-break: break-word;

  .message-content {
    margin: 16px 0;
  }

  .title {
    font-size: 18px;
  }

  ${({ isPrompt }) =>
    isPrompt
      ? css`
          border: 1px solid #bfbfbf;
          padding: 16px;

          .top-box,
          .thumbnail {
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: bold;
          }

          .bottom-box {
            align-items: center;
            display: flex;
            gap: 8px;
            justify-content: flex-end;

            button {
              align-items: center;
              background: #efefef;
              border: none;
              border-radius: 8px;
              cursor: pointer;
              display: block;
              display: flex;
              font-size: 18px;
              height: 42px;
              justify-content: center;
              transition: all 0.3s;
              width: 50px;
            }
          }
        `
      : css`
          margin-top: 30px;
          .message-content {
            padding-right: 40px;
            position: relative;

            .regenerate {
              position: absolute;
              right: 4px;
              top: 2px;
            }
          }
        `}
`;

interface MessageBoxProps {
  data: Message;
  isLoading?: boolean;
  regenerateMessage?: (message: string) => void;
}

const MessageBox = ({ data, isLoading = false, regenerateMessage }: MessageBoxProps) => {
  // STATES
  // const [isLiked, setLiked] = useState(false);
  // const [isDisliked, setDisliked] = useState(false);
  const [isCopied, setCopied] = useState(false);

  // HANDLERS
  // const handleLike = () => {
  //   setLiked(!isLiked);
  //   setDisliked(false);
  // };
  // const handleDislike = () => {
  //   setDisliked(!isDisliked);
  //   setLiked(false);
  // };
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // CONST VALS
  const messageOptions = [
    // {
    //   icon: isLiked ? faThumbsUpSolid : faThumbsUp,
    //   title: isLiked ? 'Unlike' : 'Like',
    //   onClick: handleLike,
    //   isActive: isLiked
    // },
    // {
    //   icon: isDisliked ? faThumbsDownSolid : faThumbsDown,
    //   title: isDisliked ? 'Undislike' : 'Dislike',
    //   onClick: handleDislike,
    //   isActive: isDisliked
    // },
    // {
    //   icon: faFlag,
    //   title: 'Report',
    //   onClick: () => null
    // },
    {
      title: isCopied ? 'Copied' : 'Copy',
      child: (
        <CopyToClipboard text={data.message} onCopy={handleCopy}>
          <button className={isCopied ? 'active' : ''}>
            <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
          </button>
        </CopyToClipboard>
      )
    }
  ];

  // CUSTOM COMPONENTS
  const renderUserMessage = () => {
    return (
      <div className="message-content">
        <span>{data.message}</span>
        <Tooltip title={isLoading ? '' : 'Regenerate'}>
          <FontAwesomeIcon
            icon={faRepeat}
            onClick={() => {
              if (isLoading || !regenerateMessage) return;
              regenerateMessage(data.message);
            }}
            className="regenerate"
            style={{
              opacity: isLoading ? 0.5 : 1,
              cursor: isLoading ? 'auto' : 'pointer'
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const renderPromptMessage = () => {
    return (
      <>
        <div className="top-box">
          <div className="thumbnail">
            <img className="logo" src="/assets/images/logo-post-2.png" alt="" width="50" />
            <span className="title">
              {/* {data?.model ?? 'GPT'} */}
              Corporate Insights AI Agent
            </span>
          </div>
          {data.isSpeech && (
            <div className="speech-box">
              <FontAwesomeIcon icon={faCommentDots} />
              <span>Speech</span>
            </div>
          )}
        </div>
        {data.isLoading ? (
          <div className="message-loader">
            <PulseLoader size={8} />
          </div>
        ) : (
          <>
            <div className="message-content">
              <span>{data.message}</span>
            </div>
            {data.isCompleted && (
              <div className="bottom-box">
                {messageOptions.map((option, index) => (
                  <Tooltip title={option.title} key={index}>
                    {option.child ? (
                      option.child
                    ) : null
                    /*(
                      <button onClick={option.onClick} className={option.isActive ? 'active' : ''}>
                        <FontAwesomeIcon icon={option.icon} />
                      </button>
                    )*/
                    }
                  </Tooltip>
                ))}
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <MessageBoxWrapper isPrompt={data.isPrompt}>
      {data.isPrompt ? renderPromptMessage() : renderUserMessage()}
    </MessageBoxWrapper>
  );
};

MessageBox.displayName = 'MessageBox';
export default MessageBox;
