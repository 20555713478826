import { createSlice } from '@reduxjs/toolkit';
import { Message } from '../../types/message';

export interface ChatState {
  messages: Message[];
}

const initialState: ChatState = {
  messages: []
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    sendMessageRequest(state, action) {
      const message = {
        message: action.payload
      };
      const tempdate = [...state.messages, message];
      state.messages = tempdate;
    },
    recieveMessageRequest(state, action) {
      state.messages = [...state.messages, action.payload];
    },
    refreshMessages(state) {
      state.messages = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { sendMessageRequest, recieveMessageRequest, refreshMessages } = chatSlice.actions;

export default chatSlice.reducer;
