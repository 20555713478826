import { createSlice } from '@reduxjs/toolkit';

export const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    files: [] as any[]
  },
  reducers: {
    loadMedia(state, { payload }) {
      state.files = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { loadMedia } = mediaSlice.actions;

export default mediaSlice.reducer;
