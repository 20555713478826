import React, { useState } from 'react';
import { Button, Form, Input as AntInput } from 'antd';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
// import { useMediaQuery } from 'usehooks-ts';

const Wrapper = styled.div`
  max-width: 100%;
  width: 1000px;

  .ant-input-affix-wrapper {
    border-color: #747474;
    border-radius: 8px;
    box-shadow: none;
    font-size: 16px;
    padding: 4px 16px;
    &:hover {
      border-color: #760033;
    }
    .ant-input-clear-icon {
      color: #efefef;
      font-size: 22px;
      .anticon-close-circle {
        background: #000;
        border-radius: 50px;
      }
    }
  }

  .ant-input-affix-wrapper .ant-input-clear-icon .anticon-close-circle {
    background: #000;
    border-radius: 50px;
  }
`;

const Input = styled(AntInput)`
  height: 45px;
  transition: none;
  padding-right: 70px;

  &:hover,
  &:focus {
    border: 2px solid #ed4e23;
  }
`;

const SubmitButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #131312;

  // background: #131312;
  // color: #fff;
  // border: 2px solid #ed4e23;
  // height: 50px;
  // border-radius: 25px;
  // padding: 14px 25px;
  // font-size: 18px;
  // font-weight: bold;
  // cursor: pointer;

  // &:disabled {
  //   cursor: not-allowed;
  // }

  // @media (max-width: 768px) {
  //   /* Add mobile specific styles here */
  //   padding: 0;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex: 0 0 44px;
  //   height: 44px;
  //   border-radius: 50%;
  // }
`;

// const MediaButton = styled.button`
//   padding: 14px 19px;
//   background: #fff 0% 0% no-repeat padding-box;
//   border: 2px solid #ed4e23;
//   height: 50px;
//   font-size: 18px;
//   line-height: 20px;
//   border-radius: 25px;
//   color: #000000;
//   font-weight: bold;
//   cursor: pointer;
//   white-space: nowrap;
//   display: flex;
//   align-items: center;
//   gap: 6px;

//   &:disabled {
//     cursor: not-allowed;
//   }

//   @media (max-width: 768px) {
//     padding: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex: 0 0 44px;
//     height: 44px;
//     border-radius: 50%;
//   }
// `;

const StyledForm = styled(Form)`
  display: flex;
  gap: 16px;
  position: relative;
`;

const Tagline = styled.div`
  color: 191919;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
`;

interface InputBoxProps {
  isLoading: boolean;
  sendMessage: (message: string) => void;
}

const InputBox = ({ sendMessage, isLoading }: InputBoxProps) => {
  // const isMobile = useMediaQuery('(max-width: 768px)');
  // STATES
  // const [chatOptionPreview, setChatOptionPreview] = useState(false);
  const [message, setMessage] = useState('');

  // CONST VALS
  const [form] = Form.useForm();
  // const chatOptions = CHAT_OPTIONS.filter((x) => x.value !== chatOption.value);

  // HANDLERS
  // const setSelectedChatOptionHandler = (item) => {
  //   setChatOption(item);
  //   setChatOptionPreview(false);
  // };
  // const setChatOptionPreviewHandler = () => {
  //   setChatOptionPreview(!chatOptionPreview);
  // };
  const setMessageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };
  const generateMessageHandler = () => {
    // if (isLoading) {
    //   stopResponseGenerating();
    //   return;
    // }
    // if (message.length >= 1) {
    form.validateFields().then(() => {
      setMessage('');
      sendMessage(message);
    });
    // }
  };

  // HOOKS
  // useEffect(() => {
  //   // function to close chat option preview on outside click
  //   document.addEventListener("click", (e) => {
  //     if (
  //       !e.target.closest(".chat-selector") &&
  //       !e.target.closest(".chat-options")
  //     ) {
  //       setChatOptionPreview(false);
  //     }
  //   });
  // }, []);

  return (
    <Wrapper>
      <StyledForm className="input-box" form={form} onSubmitCapture={generateMessageHandler}>
        <Input placeholder="Message AI agent..." value={message} onChange={setMessageHandler} />
        <SubmitButton
          // onClick={generateMessageHandler}
          // className={isLoading ? "disconnect" : ""}
          // disabled={!isLoading && message.length < 1}
          type="text"
          htmlType="submit"
          disabled={message.length < 1 || isLoading}
        >
          {/* {isMobile ? <FontAwesomeIcon icon={faPaperPlane} /> : 'Generate'} */}
          <FontAwesomeIcon icon={faPaperPlane} />
        </SubmitButton>
        {/* <MediaButton>
          <FontAwesomeIcon icon={faPhotoFilm} />
          {!isMobile && 'Media library'}
        </MediaButton> */}
      </StyledForm>
      <Tagline>Generative AI technology, including this AI Agent, can generate errors and inaccurate data. Always cross-verify key details with authoritative sources.</Tagline>
    </Wrapper>
  );
};

export default InputBox;
